import { useState, useEffect } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

type IframePositionKeys = 'left';

type IframePositionOptions = {
  [key in IframePositionKeys]?: string;
};

type ConfigurableIframeOptions = null | {
  className?: string;
  containerElement?: HTMLElement;
  protectAgainstGlobalStyleBleed?: boolean;
  position?: IframePositionOptions;
};

const AvatarMenu = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isCopied, setIsCopied] = useState(false);

  const wallet = localStorage.getItem('walletAddress');
  const cachedUser = JSON.parse(localStorage.getItem('cachedUser') || '{}');

  const handleCopyToClipboard = () => {
    if (wallet) {
      navigator.clipboard.writeText(wallet);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000); // Reset the "copied" message after 1 second
    }
  };

  const [indexLanguage, setIndexLanguage] = useState('');

useEffect(() => {
  // Get the language from localStorage when the component mounts
  const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
  setIndexLanguage(storedLanguage);
}, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    const getWalletAddress = localStorage.getItem('walletAddress');

    const fetchUserData = async () => {
      try {
        if (cachedUser && cachedUser.wallet === getWalletAddress) {
          // Use cached data if the wallet address is the same
          setUser(cachedUser.userData);
        } else {
          const response = await fetch(
            `https://peaxel.me/wp-json/custom/v1/get_user_by_metadata/?value=${getWalletAddress}`
          );
          const userData = await response.json();

          if (userData && userData.length > 0) {
            setUser(userData[0]);
            // Cache the user data
            localStorage.setItem('cachedUser', JSON.stringify({ wallet: getWalletAddress, userData: userData[0] }));
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [cachedUser]);

  const getInitials = (name: any) => {
    if (!name) return '';

    const names = name.split(' ');
    return names
      .map((word: any) => word.charAt(0).toUpperCase())
      .join('')
      .slice(0, 2);
  };

  return (
    <div>
      {loading && <p>Loading...</p>}
      {user && (
        <span className="avatar-big-menu-container">
          <span className="avatar-big-menu">{getInitials(user)}</span>
          <span className="name-big-menu">{user}&nbsp;</span>
          <span className="wallet-menu-big" onClick={handleCopyToClipboard}>
            @ {wallet
              ? wallet.length >= 8
                ? `${wallet.slice(0, 6)} ... ${wallet.slice(-8)}`
                : wallet
              : 'N/A' /* Or any other fallback value you prefer */}
            &nbsp;&nbsp;
            <span className="menu-items-inverse">
              <FontAwesomeIcon icon={faCopy} onClick={handleCopyToClipboard} />
            </span>
            {isCopied && <span className="copied-message">Copied!</span>}
          </span>
        </span>
      )}
    </div>
  );
};

export default AvatarMenu;
